exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bills-[id]-tsx": () => import("./../../../src/pages/bills/[id].tsx" /* webpackChunkName: "component---src-pages-bills-[id]-tsx" */),
  "component---src-pages-bills-index-tsx": () => import("./../../../src/pages/bills/index.tsx" /* webpackChunkName: "component---src-pages-bills-index-tsx" */),
  "component---src-pages-bills-preview-tsx": () => import("./../../../src/pages/bills/preview.tsx" /* webpackChunkName: "component---src-pages-bills-preview-tsx" */),
  "component---src-pages-departments-[id]-tsx": () => import("./../../../src/pages/departments/[id].tsx" /* webpackChunkName: "component---src-pages-departments-[id]-tsx" */),
  "component---src-pages-departments-index-tsx": () => import("./../../../src/pages/departments/index.tsx" /* webpackChunkName: "component---src-pages-departments-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-payments-index-tsx": () => import("./../../../src/pages/payments/index.tsx" /* webpackChunkName: "component---src-pages-payments-index-tsx" */),
  "component---src-pages-pois-tsx": () => import("./../../../src/pages/pois.tsx" /* webpackChunkName: "component---src-pages-pois-tsx" */),
  "component---src-pages-salaries-[id]-tsx": () => import("./../../../src/pages/salaries/[id].tsx" /* webpackChunkName: "component---src-pages-salaries-[id]-tsx" */),
  "component---src-pages-salaries-index-tsx": () => import("./../../../src/pages/salaries/index.tsx" /* webpackChunkName: "component---src-pages-salaries-index-tsx" */),
  "component---src-pages-salaries-preview-tsx": () => import("./../../../src/pages/salaries/preview.tsx" /* webpackChunkName: "component---src-pages-salaries-preview-tsx" */),
  "component---src-pages-tes-[id]-contract-tsx": () => import("./../../../src/pages/tes/[id]/contract.tsx" /* webpackChunkName: "component---src-pages-tes-[id]-contract-tsx" */),
  "component---src-pages-tes-[id]-index-tsx": () => import("./../../../src/pages/tes/[id]/index.tsx" /* webpackChunkName: "component---src-pages-tes-[id]-index-tsx" */),
  "component---src-pages-tes-[id]-settings-tsx": () => import("./../../../src/pages/tes/[id]/settings.tsx" /* webpackChunkName: "component---src-pages-tes-[id]-settings-tsx" */),
  "component---src-pages-tes-index-tsx": () => import("./../../../src/pages/tes/index.tsx" /* webpackChunkName: "component---src-pages-tes-index-tsx" */),
  "component---src-pages-users-[id]-tsx": () => import("./../../../src/pages/users/[id].tsx" /* webpackChunkName: "component---src-pages-users-[id]-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */)
}

